import {
	BrowserRouter as Router,
	Routes,
	Route,
} from 'react-router-dom';
import Home from './Home.js';
import "react-vertical-timeline-component/style.min.css";

function App() {
 return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Home/>}></Route>
      </Routes>
    </Router>
  );
}

export default App;
