import Spline from "@splinetool/react-spline";
import Logo from "./images/logo.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { Features, Roadmap, SocialLinks } from "./data";
import "./index.css";
import { IoMenu } from "react-icons/io5";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";


function Home() {
    const [isActive, setIsActive] = useState(false);
    return (
      <AnimatePresence initial={false}>
        <div className="flex w-full min-h-screen flex-col items-center justify-center relative pb-20">
        {/* Navigation Code Begins */}
        <nav className="w-full px-6 z-50  fixed inset-x-0 top-2 flex justify-center items-center ">
            <div className="w-full md:w-880 bg-navBar backdrop-blur-2 p-4 rounded-2xl flex items-center ">
              <img src={Logo} alt='Orbitain' className="h-6" />
  
              <div className="hidden md:flex items-right gap-6 ml-6 flex-1">
              <a
                    href="#home"
                    className="textShadow text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    HOME
                  </a>
                  <a
                    href="#services"
                    className="textShadow text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    SERVICES
                  </a>
                  <a
                    href="#roadmap"
                    className="textShadow text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    ROADMAP
                  </a>
                  <a
                    href="#project"
                    className="textShadow text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    PROJECTS
                  </a>
                  <a
                    href="#community"
                    className="textShadow text-base text-textBase font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    COMMUNITY
                  </a>
              </div>
  
              <motion.div
                whileTap={{ scale: 0.6 }}
                className="block md:hidden  ml-auto cursor-pointer "
                onClick={() => setIsActive(!isActive)}
              >
                <IoMenu className="text-2xl text-textBase " />
              </motion.div>
              {isActive && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1.1 }}
                  exit={{ opacity: 0, scale: 0.5 }}
                  transition={{ delay: "0.1s", type: "spring" }}
                  className="p-4 w-275 bg-navBarTwo rounded-3xl fixed top-20 right-6 flex flex-col items-center justify-evenly gap-6"
                >
                  <a
                    href="#home"
                    className="textShadow text-base text-textBaseTwo font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    HOME
                  </a>
                  <a
                    href="#services"
                    className="textShadow text-base text-textBaseTwo font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    SERVICES
                  </a>
                  <a
                    href="#roadmap"
                    className="textShadow text-base text-textBaseTwo font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    ROADMAP
                  </a>
                  <a
                    href="#project"
                    className="textShadow text-base text-textBaseTwo font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    PROJECTS
                  </a>
                  <a
                    href="#community"
                    className="textShadow text-base text-textBaseTwo font-medium hover:text-slate-100 cursor-pointer duration-100 ease-in"
                    onClick={() => setIsActive(false)}
                  >
                    COMMUNITY
                  </a>
                </motion.div>
              )}
            </div>
          </nav>
          {/* Navigation End */}
  
          <div className="relative w-full h-800" id="home">
          <Spline scene="https://prod.spline.design/a-A7uafolcwQDVGS/scene.splinecode" />

            <div className="visible sm:invisible absolute bottom-10 w-full flex justify-center items-center">
              <div className="shadow-md backdrop-blur-2 p-4 flex items-center justify-center bg-opasityBg rounded-3xl ">
                <p className="text-white">Touch two fingers and drag</p>
              </div>
            </div>
          </div>
                
          <main className="w-[80%] mt-4">
            
          <section
              className="relative grid grid-cols-1 md:grid-cols-2 gap-2 my-12"
            >
              <div className="w-full h-600 flex items-center justify-center relative rounded-md">
              <Spline scene="https://prod.spline.design/0OvL1GJvQIs-nN51/scene.splinecode" />
              </div>
              <div className="w-full h-600 flex flex-col items-center justify-center ">
                <h1 className="text-2xl text-textBase text-center mt-8 mb-4">About Aayud</h1>
                <p className="text-lg text-textBase text-justify">
                Aayud Technologies Private Limited is a leading blockchain development company that specializes in Blockchains and Web3 Dapps business development. Our team of experienced developers have years of experience in the field, and we use the latest technology to create innovative blockchain solutions.
                </p>
                <p className="text-lg text-textBase text-justify mt-4">
                Our team has a proven track record of success, and we have a portfolio of successful clients that include some of the world's leading companies. We are passionate about our work, and we take pride in our ability to deliver high-quality products and services. We understand the importance of customer satisfaction, and we always go the extra mile to ensure that our clients are happy.
                </p>
                <p className="text-lg text-textBase text-justify mt-4">
                We believe that blockchain technology has the potential to revolutionize the way businesses operate, and we are dedicated to helping it reach its full potential. We are excited to bring our unique skills and experience to the market.
                </p>
              </div>
              
          </section>
                <div className="h-80"></div>
            <h1 className="text-2xl text-textBase text-center mb-4" id="services">Services</h1>
            <section
              className="flex flex-wrap items-start justify-evenly my-12 gap-4" 
            >{Features &&
              Features.map((n, i) => (
                <motion.div
                  key={n.id}
                  className="border border-zinc-800 rounded-md p-2 min-h-[380px] min-w-[275px] md:max-w-[275px] hover:border-zinc-600 duration-100 ease-in-out"
                >
                  <p className="text-lg text-textBase font-medium uppercase">
                    {n.name.length > 25 ? `${n.name.slice(0, 25)}...` : n.name}
                  </p>
  
                  <img
                    src={n.imageSrc}
                    className="w-full h-full object-cover rounded-md my-4"
                    alt=""
                  />
  
                  <div className="flex flex-1 items-center justify-between">
                    <p className="text-lg text-gray-300">
                      
                      <span className="block text-sm text-gray-500 text-justify">
                        {n.techs}
                      </span>
                    </p>
                  </div>
                </motion.div>
              ))}
  
            </section>
            <div id="roadmap" className="mt-12"></div>
            <h1 className="text-2xl text-textBase text-center">RoadMap</h1>          
            <section  className="w-full flex items-center justify-center my-12">
            <VerticalTimeline>
                {Roadmap &&
                  Roadmap.map((n) => (
                    <VerticalTimelineElement
                      key={n.id}
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        background: "rgb(255, 68, 68)",
                        color: "#fff",
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  rgb(255, 68, 68)",
                      }}
                      date={n.date}
                      iconStyle={{ background: "rgb(255, 68, 68)", color: "#fff" }}
                      icon={n.iconsSrc}
                    >
                      <h3 className="vertical-timeline-element-title">
                        {n.title}
                      </h3>
                      <h4 className="vertical-timeline-element-subtitle">
                        {n.status}
                      </h4>
                      <p className="text-justify">{n.description}</p>
                    </VerticalTimelineElement>
                  ))}
              </VerticalTimeline>
            </section>
            
            <div id="project" className="mt-12"></div>
            <h1 className="text-2xl text-textBase text-center">Upcoming Project</h1>
            <section
              className="relative w-full grid grid-cols-1"
            >
              
              <div className="w-full h-420 flex items-center justify-center">
                <div className="w-full h-420 relative rounded-md">
                <Spline scene="https://prod.spline.design/SeX-pWCGhttBtHT3/scene.splinecode"/>
                </div>
              </div>
              <div className="w-280 flex flex-col items-center justify-center mt-2">
                <h3 className="text-2xl text-justify mb-8">About METAPLAYER.ONE</h3>
                <p className="text-lg text-textBase text-justify mb-4">
                METAPLAYER.ONE is a new blockchain gaming platform that allows gamers to explore, trade, and battle with one another in a completely safe and transparent environment. It's essentially a virtual world where players can do anything they want, including buying, selling, and exchanging items and characters. And because METAPLAYER.ONE is built on the blockchain, it's secure and transparent - meaning that everyone can trust it. This makes it a great choice for gamers who want to be sure their transactions are safe and fair. In addition, METAPLAYER.ONE offers a wide range of gaming options, including online games, Mvp battles, and even in-game shops. So whether you're looking for a new online game to play or you're looking for a way to trade items with other players securely and easily, Metaplayerone is the perfect platform for gamers.            
               </p>
              </div>
            </section>
  
            <section
              id="community"
              className="relative flex flex-col items-center justify-evenly w-full my-24"
            >
              <p className="text-2xl text-gray-700 capitalize">Join Us on</p>
              <div className="flex items-center justify-evenly w-full my-4 flex-wrap gap-4">
                {SocialLinks &&
                  SocialLinks.map((n) => (
                    <motion.a
                      whileTap={{ scale: 0.8 }}
                      href={n.link}
                      key={n.id}
                      className="w-full md:w-auto px-3 md:px-8 py-5 border border-zinc-800 rounded-full hover:border-zinc-600 duration-100 ease-in-out cursor-pointer flex items-center justify-center gap-3"
                    >
                      {n.iconSrc}
                      <p className="text-lg text-textBase">{n.name}</p>
                    </motion.a>
                  ))}
              </div>
            </section>
          </main>
  
        </div>        
      </AnimatePresence>
    );
  }


  export default Home;