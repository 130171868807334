import {
    FaBrain, FaCoins, FaParachuteBox, FaPiggyBank,
    FaGithub,
    FaTwitter,
    FaDiscord,
    FaFacebook,
    FaCode,
    FaCodeBranch,
    FaNetworkWired
  } from "react-icons/fa";

import transaction from "./images/transaction.png";
import blockchain from "./images/blockchain.png";
import asset from "./images/asset.png";
import chat from "./images/chat.png";
import streaming from "./images/streaming.png";
import exchange from "./images/exchange.png";

  export const Roadmap = [
    {
      id: 1,
      date: "Q3 - 2022",
      iconsSrc: <FaBrain />,
      title: "Metaplayer.one Ideation",
      status: "Completed",
      description:
        "",
    },
    {
      id: 2,
      date: "Q4 - 2022 | Present",
      iconsSrc: <FaCode />,
      title: "In Dev Ecosystem",
      status: "Started",
      description:
        "",
    },
    {
      id: 3,
      date: "Q1 - 2023",
      iconsSrc: <FaParachuteBox />,
      title: "Social Airdrop & Bounties",
      status: "Processing",
      description:
        "",
    },
    {
      id: 4,
      date: "Q2 - 2023",
      iconsSrc: <FaPiggyBank />,
      title: "NFTs Sale",
      status: "Processing",
      description:
        "",
    },
    {
      id: 5,
      date: "Q2 - 2023",
      iconsSrc: <FaCoins />,
      title: "List Coin in Exchange",
      status: "Planned",
      description:
        "",
    },
    {
        id: 6,
        date: "Q3 - 2023",
        iconsSrc: <FaCodeBranch />,
        title: "Mainnet Launch",
        status: "Planned",
        description:
          "",
    },
    {
        id: 7,
        date: "Q3 - 2023",
        iconsSrc: <FaNetworkWired />,
        title: "Games Launch",
        status: "Planned",
        description:
          "",
    },
  ];


  export const Features = [
    {
      id: 1,
      name: "Wallet Dev",
      imageSrc: transaction,
      techs: "Looking to develop a blockchain wallet for your business? Look no further than our EVM blockchain wallet development service. Our team of experienced developers can help you create a custom blockchain wallet that meets your specific needs.",
    },
    {
      id: 2,
      name: "Blockchain Dev",
      imageSrc: blockchain,
      techs: "We can help you build a strong, secure EVM blockchain that meets your specific needs. Our team of developers have years of experience in the field, and we know how to create a platform that is both user-friendly and scalable. We also understand the importance of security, which is why we make sure every step of the development process is carried out in a secure environment. ",
    },
    {
      id: 3,
      name: "Dex Dev",
      imageSrc: exchange,
      techs: "Dex is a decentralized exchange that's designed for the modern cryptocurrency trader. We offer an easy-to-use platform that makes trading cryptocurrencies a breeze. Plus, we're constantly working on new features and enhancements to make trading even more user friendly. Dex is the perfect solution for those who want to diversify their portfolio and increase their investment opportunities. With Dex, you can trade a variety of cryptocurrencies and altcoins, and you can also use our platform to store your cryptocurrencies in a safe and secure environment. In short, Dex is the perfect platform for anyone who wants to take their trading to the next level.",
    },
    {
      id: 4,
      name: "Web3-Dapps Dev",
      imageSrc: asset,
      techs: "As the popularity of dapps continues to grow, it's important for developers to have access to quality tools and resources. That's why we're excited to announce our partnership with Solidity, the leading development platform for Ethereum dapps. Solidity provides developers with everything they need to build high-quality dapps, including a robust API, a code editor, and support for multiple languages.",
    },
    {
      id: 5,
      name: "P2P-Dapps Dev",
      imageSrc: chat,
      techs: "Looking for a p2p messaging web3 dapps chat development service? Look no further than our team of experts! We have years of experience developing and building custom p2p messaging apps, and we're confident we can help you create a high-quality product that meets your specific needs.",
    },
    {
      id: 6,
      name: "IPFS-Dapps Dev",
      imageSrc: streaming,
      techs: "PFS is a new technology that allows for the distribution of large files without the need for a central server. This is great for two reasons: first, it eliminates the risk of data loss or corruption. Second, it makes it possible to create decentralized applications (dapps) that can be used without the need for a central server. With ipfs storage and dapps development service, you can easily create and launch your own dapps. We will help you design and build the necessary infrastructure, so you can focus on developing your dapps. In addition, we will provide you with 24/7 support, so you can always be sure that your data is safe and secure.",
    },
  ];
  

  export const SocialLinks = [
    {
      id: 1,
      iconSrc: (
        <FaTwitter className="text-blue-500 text-3xl cursor-pointer" />
      ),
      name: "Twitter",
      link: "#",
    },
    {
      id: 2,
      iconSrc: (
        <FaDiscord className="text-indigo-800 text-3xl cursor-pointer" />
      ),
      name: "Discord",
      link: "#",
    },
    {
      id: 3,
      iconSrc: <FaGithub className="text-textBase text-3xl cursor-pointer" />,
      name: "GitHub",
      link: "#",
    },
    {
      id: 4,
      iconSrc: (
        <FaFacebook className="text-blue-500 text-3xl cursor-pointer" />
      ),
      name: "Facebook",
      link: "#",
    },
  ];